/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import currency from 'currency.js'
import { Card } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import styled from 'styled-components'
import dateFormat from '../helpers/dateFormat'
// services
import {
  getTypeTransactions,
  getTransactions,
  getPeriodTransactions,
  updateTransaction
} from '../../services/transactions'
import translateTransaction from '../helpers/translateTransactions'
import { ExtendedTransaction } from './interfaces'
import Loader from '../layout/shared/loader'
import downArrow from '../../assets/icons/downArrow.png'
import upArrow from '../../assets/icons/upArrow.png'
import { layoutValues, colors } from '../layout/constants'

const TransactionsContainer = styled.section`
  width: 100%;

  .trans-table {
    text-align: center;
  }

  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
  }

  .sorting-div {
    display: flex;
    flex-direction: row;
    padding: 0.3rem 0.7rem;
    justify-content: center;
  }

  .sort-select {
    padding: 1rem;
    margin: 0.5rem;
  }

  .sort-btn {
    border: none;
    background-color: transparent;
  }

  .sorting-arrow {
    height: 1rem;
    width: 1rem;
    padding-right: 0.3rem;
    padding-top: 0.8rem;
    cursor: pointer;
  }

  .user-link,
  .user-link:link,
  .user-link:visited {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }

  .type-label {
    border-radius: ${layoutValues.cardRadius};
    display: inline-block;
    padding: 8px;
    text-transform: capitalize;
    text-align: center;
    width: 100px;
    position: relative;
    cursor: pointer;

    &.PENDING {
      background-color: orange;
    }

    &.PAID {
      background-color: lightgreen;
    }

    &.DECLINED {
      color: white;
      background-color: darkred;
    }

    &.CANCEL {
      color: white;
      background-color: darkred;
    }
  }

  .status-dropdown {
    background-color: lightgray;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    display: none;
    border-radius: 8px;

    &.show {
      display: block;
    }

    ul {
      list-style: none;
    }

    li {
      padding: 2px 5px;

      &:hover {
        background-color: gray;
      }
    }
  }
`

const TransactionsView = (): JSX.Element => {
  const [transactions, setTransactions] = useState<ExtendedTransaction[]>([])
  const [count, setCount] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [sortedBy, setSortedBy] = useState('transaction_date')
  const [descendingOrder, setDescendingOrder] = useState(true)
  const [filteredType, setFilteredType] = useState('all')
  const [filteredDate, setFilteredDate] = useState('all')
  const [filteredYear, setFilteredYear] = useState('---')

  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  const handlePageChange = (e: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleOrderChange = (sorted: string): void => {
    setSortedBy(sorted)
    setDescendingOrder(!descendingOrder)
    setPage(0)
  }

  const handleTypeFilterChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.target
    setFilteredDate('all')
    setFilteredYear('---')
    setFilteredType(value)
  }

  const monthsToFilter = [
    { value: 'all', text: 'Todos los Meses' },
    { value: '01-01', text: 'Enero' },
    { value: '02-01', text: 'Febrero' },
    { value: '03-01', text: 'Marzo' },
    { value: '04-01', text: 'Abril' },
    { value: '05-01', text: 'Mayo' },
    { value: '06-01', text: 'Junio' },
    { value: '07-01', text: 'Julio' },
    { value: '08-01', text: 'Agosto' },
    { value: '09-01', text: 'Septiembre' },
    { value: '10-01', text: 'Octubre' },
    { value: '11-01', text: 'Noviembre' },
    { value: '12-01', text: 'Diciembre' }
  ]

  const yearsToFilter = ['---', '2025', '2024', '2023', '2022', '2021']

  const handleDateChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    if (e.target.value === 'all') {
      setFilteredYear('---')
    }
    setFilteredType('all')
    setFilteredDate(e.target.value)
  }
  const handleYearChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    setFilteredType('all')
    setFilteredYear(e.target.value)
  }

  const setTransactionList = async (): Promise<void> => {
    let order = 'DESC'
    if (descendingOrder === false) order = 'ASC'

    setIsLoading(true)
    if (filteredType !== 'all') {
      const newList = await getTypeTransactions(
        userToken,
        filteredType,
        page,
        sortedBy,
        order
      )

      setTransactions(newList.data)
      setCount(newList.info.count)
      setIsLoading(false)
      return
    }
    if (filteredDate !== 'all') {
      if (filteredYear === '---') setFilteredYear('2025')
      const searchDate = `${filteredYear}-${filteredDate}`
      const newList = await getPeriodTransactions(
        userToken,
        searchDate,
        page,
        sortedBy,
        order
      )

      setTransactions(newList.data)
      setCount(newList.info.count)
      setIsLoading(false)
      return
    }

    const newList = await getTransactions(userToken, page, sortedBy, order)

    setTransactions(newList.data)
    setCount(newList.info.count)
    setIsLoading(false)
  }

  useEffect(() => {
    setTransactionList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, descendingOrder, filteredType, filteredDate, filteredYear])

  if (isLoading) {
    return <Loader text="cargando" />
  }

  const StatusDropdown = ({
    status,
    id
  }: {
    status: string
    id: number
  }): JSX.Element => {
    const [active, setActive] = useState<boolean>(false)
    const [dropdownStatus, setDropdownStatus] = useState<string>(status)
    function toggleModal(): void {
      setActive(!active)
    }

    async function handledClick(newStatus: string): Promise<void> {
      // eslint-disable-next-line no-param-reassign
      const updated = await updateTransaction(userToken, id, {
        status: newStatus
      })
      setDropdownStatus(newStatus)
      setActive(false)
    }

    return (
      <>
        {dropdownStatus && (
          <button
            className={`type-label pending ${dropdownStatus}`}
            onClick={() => toggleModal()}
            type="button"
          >
            <p>{dropdownStatus}</p>
          </button>
        )}

        <div className={`${active ? 'show' : ''} status-dropdown`}>
          <ul>
            <li onClick={() => handledClick('PENDING')}>Pendiente</li>
            <li onClick={() => handledClick('PAID')}>Pagado</li>
            <li onClick={() => handledClick('CANCEL')}>Cancelado</li>
            <li onClick={() => handledClick('DECLINED')}>Declinado</li>
          </ul>
        </div>
      </>
    )
  }

  return (
    <TransactionsContainer>
      <Card>
        <div className="sorting-div">
          <div className="sorting-el">
            <h4>Tipo de transacción</h4>
            <select
              className="sort-select"
              value={filteredType}
              onChange={handleTypeFilterChange}
            >
              <option value="all">Todas</option>
              <option value="COBRANZA_DEPOSITO">Abono</option>
              <option value="COBRANZA_DEPOSITO_RECURRENTE">
                Abono recurrente
              </option>
              <option>Compra</option>
              <option>venta</option>
            </select>
          </div>
          <div className="sorting-el">
            <h4>Mes de transacción</h4>
            <select
              className="sort-select"
              value={filteredDate}
              onChange={handleDateChange}
            >
              {monthsToFilter.map((month) => (
                <option value={month.value} key={month.value}>
                  {month.text}
                </option>
              ))}
            </select>
          </div>
          <div className="sorting-el">
            <h4>Año de transacción</h4>
            <select
              className="sort-select"
              value={filteredYear}
              onChange={handleYearChange}
            >
              {yearsToFilter.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>

        <TableContainer component={Paper} className="table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="right">ID de transacción</TableCell>
                <TableCell align="right">
                  <button
                    className="sort-btn"
                    type="button"
                    onClick={() => handleOrderChange('transaction_date')}
                  >
                    {descendingOrder ? (
                      <img
                        src={downArrow}
                        alt="sort"
                        className="sorting-arrow"
                      />
                    ) : (
                      <img src={upArrow} alt="sort" className="sorting-arrow" />
                    )}
                  </button>
                  Fecha
                </TableCell>
                <TableCell>Nombre Usuario</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Estatus</TableCell>

                <TableCell align="right">
                  <button
                    className="sort-btn"
                    type="button"
                    onClick={() => handleOrderChange('subtotal')}
                  >
                    {descendingOrder ? (
                      <img
                        src={downArrow}
                        alt="sort"
                        className="sorting-arrow"
                      />
                    ) : (
                      <img src={upArrow} alt="sort" className="sorting-arrow" />
                    )}
                  </button>
                  Subtotal
                </TableCell>
                <TableCell align="right">IVA</TableCell>
                <TableCell align="right">ISR</TableCell>
                <TableCell align="right">Retención</TableCell>
                <TableCell align="right">Neto</TableCell>
                <TableCell align="center">Método de Pago</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-body">
              {transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell align="center">{transaction.id}</TableCell>
                  <TableCell align="right">
                    {dateFormat(transaction.transaction_date, 'short')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link
                      className="user-link"
                      to={`/usuarios/${transaction.user.id}`}
                    >
                      {`${transaction.user.name} 
                        ${transaction.user.last_name}`}
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    {translateTransaction(transaction.type)}
                  </TableCell>
                  <TableCell align="left">
                    <div className="type-label abono">
                      {transaction?.status && (
                        <StatusDropdown
                          status={transaction?.status}
                          id={transaction?.id}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.subtotal)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.iva)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.isr)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.retention)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.net_amount)).format()}
                  </TableCell>
                  <TableCell align="center">
                    {transaction.zigu_id && `ZIGU - ${transaction.zigu_id}`}
                    {transaction.stripe_id && (
                      <a
                        href={`https://dashboard.stripe.com/payments/${transaction.stripe_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Stripe
                      </a>
                    )}
                    {(transaction.type === 'COBRANZA_DEPOSITO' ||
                      transaction.type === 'COBRANZA_DEPOSITO_RECURRENTE') &&
                    !transaction.zigu_id &&
                    !transaction.stripe_id
                      ? 'SEPI'
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100]}
          component="div"
          rowsPerPage={100}
          count={count}
          page={page}
          onPageChange={handlePageChange}
        />
      </Card>
    </TransactionsContainer>
  )
}

export default TransactionsView
